<!--
 * @Author: your name
 * @Date: 2021-12-03 08:34:35
 * @LastEditTime: 2021-12-23 10:15:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page5.vue
-->
<template>
  <div class="fx101-page5 pt80">
    <div class="text-div">
      <ctitle titleb="防逃设计，" titles="杜绝害虫二次逃脱"></ctitle>
    </div>
    <div class="img-div">
      <img
        class="data-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/8.png"
        alt="上太科技"
        @load="imgLoad"
      />
      <div class="i-desc">
        <span class="line-span"></span>
        <p class="line-label">防逃阀门</p>
      </div>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1.vue";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    imgLoad() {
      let div = document.querySelector(".fx101-page5");
      let img = div.querySelector(".data-img");
      let p = div.querySelector(".i-desc");
      p.style.left = `calc(22% + ${img.width / 2}px - 5px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fx101-page5 {
  position: relative;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .img-div {
    height: 78%;
    position: relative;
    margin-top: 60px;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .i-desc {
    position: absolute;
    top: 63%;
    overflow: hidden;
    .line-span {
      display: block;
      position: absolute;
      top: 0px;
      left: 0;
      width: 0;
      height: 1px;
      background-color: rgba($color: #fff, $alpha: 0.5);
    }
    p {
      margin: 0;
      font-weight: bold;
      font-size: 22px;
      white-space: nowrap;
      color: $color-active;
      padding-left: 100px;
      position: relative;
      height: 0;
      // height: 16px;
      margin-top: 10px;
      overflow: hidden;
    }
  }
}
@media screen and (max-height: 800px) {
  .fx101-page5 {
    .img-div {
      height: 68%;
    }
  }
}
@media screen and (min-height: 800px) {
  .fx101-page5 {
    .img-div {
      height: 78%;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .fx101-page5 {
    height: unset;
    .img-div {
      width: 40%;
      img {
        width: 100%;
      }
    }

    .i-desc {
      .line-span {
        width: 100px;
      }
      p {
        height: 16px;
        font-size: 0.22rem;
        padding-left: 0;
        text-align: right;
      }
    }
  }
}
</style>
