<!--
 * @Author: your name
 * @Date: 2021-12-03 09:47:12
 * @LastEditTime: 2021-12-10 14:30:06
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page10.vue
-->
<template>
  <div class="fx101-page10 pt80">
    <div class="text-div">
      <ctitle titleb="超长待机GPS精准定位"></ctitle>
      <ctitle titles="搭载智能防盗芯片"></ctitle>
      <p class="desc-p">防盗报警、倾倒报警、故障报警实时反馈</p>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/13.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "fx101-page10",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.fx101-page10 {
  text-align: center;
  height: 100vh;
  position: relative;
  .text-div {
    position: relative;
    z-index: 2;
    .desc-p {
      font-size: 18px;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 30px;
      margin-top: 59px;
    }
  }
  .img-div {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  ::v-deep {
    .big-p {
      margin: 0;
    }
  }
}
</style>
