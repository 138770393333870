<!--
 * @Author: your name
 * @Date: 2021-12-03 07:49:45
 * @LastEditTime: 2021-12-23 09:35:27
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\fx101\page1.vue
-->
<template>
  <div class="fx101-page1">
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/1.png"
        alt="上太科技"
      />
    </div>
    <div class="text-div">
      <span class="model-span din">ST-FX101</span>
      <span class="label-span">太阳能捕虫器</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.fx101-page1 {
  display: flex;
  justify-content: center;
  top: 0;
  width: 100%;
  .img-div {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
  }
  .text-div {
    position: absolute;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    .model-span {
      font-size: 84px;
      font-weight: 500;
      color: #ffffff;
    }
    .label-span {
      font-size: 36px;
      font-weight: bold;
      color: #ffffff;
      margin-top: 20px;
    }
  }
}
@media screen and (min-width: $mobile-width) {
  .fx101-page1 {
    height: 100vh;
    position: absolute;
    .img-div {
      height: 100%;

      img {
        height: 100%;
        // width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-80%);
        object-fit: contain;
        // object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .fx101-page1 {
    .text-div {
      width: 100%;
      .model-span {
        font-size: 1rem;
        font-weight: 500;
        color: #ffffff;
      }
      .label-span {
        font-size: 0.58rem;
        font-weight: bold;
        color: #ffffff;
        margin-top: 20px;
      }
    }
    .img-div {
      display: flex;
      height: 100%;
      justify-content: center;
      img {
        width: 100%;
        object-fit: contain;
        transform: translateX(-0.5rem);
      }
    }
  }
}
</style>
