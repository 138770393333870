<!--
 * @Author: your name
 * @Date: 2021-12-03 08:29:42
 * @LastEditTime: 2022-01-26 17:43:28
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page4.vue
-->
<template>
  <div class="fx101-page4 pt80">
    <div class="text-div">
      <ctitle titleb="不在风里雨里，" titles="一切尽在手里"></ctitle>
      <p class="small-p">
        搭载工业级智能芯片，超强数据采集、处理能力，同步云脑大数据分析、实时分析，精准控制，真实全时段虫害智能防治
      </p>
    </div>
    <div class="img-div scale-page">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/7.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1.vue";
export default {
  name: "fx101-page4",
  components: { ctitle },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page4 {
  height: 100vh;
  position: relative;
  .img-div {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .text-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 2;
    .small-p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      opacity: 0.6;
      margin-top: 50px;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .fx101-page4 {
    height: unset;

    .img-div {
      position: relative;
      z-index: 1;
      img {
        width: 100%;
        object-fit: contain;
      }
    }

    .text-div {
      .small-p {
        font-size: 0.22rem;
        line-height: 0.36rem;
        margin-top: 16px;
      }
    }
  }
}
</style>
