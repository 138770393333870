<!--
 * @Author: your name
 * @Date: 2021-12-03 08:04:17
 * @LastEditTime: 2022-01-26 17:44:43
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page3.vue
-->
<template>
  <div class="fx101-page3">
    <div class="text-div">
      <div class="top-div">
        <div class="title-span">
          <span class="model-span din">ST-FX101</span>
          <span class="label-span">虫害防治全新定义</span>
        </div>
        <div class="desc-s">
          <span>自研（专利号：ZL 202130410817.7）外观、云脑物联、智能防逃、自动清虫、全身防水、</span>
          <span>AI控光、兼容多种光源、智能报警</span>
        </div>
        <div class="num-div">
          <cnum :number="99">捕虫率</cnum>
          <cnum :number="99">灭虫率</cnum>
          <cnum :number="0">逃跑率</cnum>
        </div>
      </div>
      <div class="desc-div web-div">
        注：以上信息中，监测数据为特定试验环境下抽样监测结果，设备使用环境、使用方式及保养方式等对试验效果会有影响。
      </div>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/6.png"
        alt="上太科技"
      />
    </div>
    <div class="text-div">
      <div class="desc-div mobile-div">
        注：以上信息中，监测数据为特定试验环境下抽样监测结果，设备使用环境、使用方式及保养方式等对试验效果会有影响。
      </div>
    </div>
  </div>
</template>

<script>
import cnum from "./components/number.vue";
export default {
  name: "",
  components: {
    cnum,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page3 {
  .img-div {
    position: absolute;
    height: 80%;
    top: 15%;
    left: 70%;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .text-div {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 11vw;
    padding-top: 180px;
    padding-bottom: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    .top-div {
      height: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .title-span {
      font-size: 60px;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      .model-span {
        font-size: 60px;
      }
      .label-span {
        font-size: 60px;
      }
    }
    .desc-s {
      display: flex;
      flex-direction: column;
      font-size: 24px;
      font-family: Microsoft YaHei;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 40px;
    }
    .num-div {
      display: flex;
      justify-content: space-between;
      padding-right: 80px;
    }
  }
  .desc-div {
    font-size: 14px;
    font-weight: 400;
    color: rgba($color: #fff, $alpha: 0.4);
  }
}

@media screen and (min-width: $mobile-width) {
  .fx101-page3 {
    position: relative;
    height: 100vh;
    .text-div {
      .web-div {
        display: block;
      }
      .mobile-div {
        display: none;
      }
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .fx101-page3 {
    .img-div {
      position: relative;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      img {
        width: 50%;
        height: 100%;
        object-fit: contain;
      }
    }
    .text-div {
      position: relative;
      padding-top: 0.73rem;
      text-align: center;

      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 12px;
      .mobile-div {
        display: block;
        font-size: 0.18rem;
      }
      .web-div {
        display: none;
      }

      .title-span {
        font-size: 0.55rem;
        display: flex;
        flex-direction: column;
        .model-span {
          font-size: 0.55rem;
        }
        .label-span {
          font-size: 0.55rem;
        }
      }

      .desc-s {
        font-size: 0.27rem;
        display: block;
        line-height: 0.44rem;
        margin-top: 16px;
      }

      .num-div {
        display: flex;
        justify-content: space-between;
        padding-right: 12px;
        padding-left: 12px;
        margin-top: 24px;
      }
    }
  }
}
</style>
