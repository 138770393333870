<!--
 * @Author: your name
 * @Date: 2021-12-03 09:26:57
 * @LastEditTime: 2021-12-23 10:52:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page9.vue
-->
<template>
  <div class="fx101-page9">
    <div class="text-div">
      <span class="l-span">多种灯管接口</span>
      <span class="l2-span">兼容主流诱虫光源</span>
      <span class="d-span m-span">兼容T8诱虫灯管、E27和LED灯管等</span>
      <span class="d-span">降低安装难度与耗材成本</span>
      <span class="din n-span">320-680 nm</span>
      <span class="d2-span">诱集光源</span>
    </div>
    <img
      class="data-img"
      src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/12.png"
      alt="上太科技"
    />
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.fx101-page9 {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  .data-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .text-div {
    position: absolute;
    top: 80px;
    left: 11vw;
    display: flex;
    flex-direction: column;
    height: 100%;
    .l-span {
      font-size: 60px;
      font-weight: 300;
      color: #ffffff;
      line-height: 80px;
    }
    .l2-span {
      font-size: 60px;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
    }
    .d-span {
      font-size: 24px;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 40px;
      &.m-span {
        margin-top: 60px;
      }
    }
    .n-span {
      font-size: 60px;
      font-weight: bold;
      color: $color-active;
      line-height: 48px;
      position: absolute;
      bottom: 300px;
    }
    .d2-span {
      font-size: 22px;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 48px;
      position: absolute;
      bottom: 250px;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .fx101-page9 {
    height: auto;
    .data-img {
      width: unset;
      height: 100%;
      object-fit: cover;
      transform: translateX(-7%);
      z-index: 1;
    }
    .text-div {
      position: absolute;
      top: 80px;
      left: 11vw;
      display: flex;
      flex-direction: column;
      height: 100%;
      z-index: 2;
      .l-span {
        font-size: 0.55rem;
        line-height: 0.73rem;
      }
      .l2-span {
        font-size: 0.55rem;
        line-height: 0.73rem;
      }
      .d-span {
        font-size: 0.22rem;
        font-weight: 400;
        line-height: 0.36rem;
        &.m-span {
          margin-top: 0.55rem;
        }
      }
      .n-span {
        font-size: 0.55rem;

        font-weight: bold;
        color: $color-active;
        line-height: 0.44rem;
        position: absolute;
      }
      .d2-span {
        font-size: 0.2rem;
        font-weight: 400;
        line-height: 0.44rem;
      }
    }
  }
}
</style>
