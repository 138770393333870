<!--
 * @Author: your name
 * @Date: 2021-12-03 09:13:47
 * @LastEditTime: 2021-12-23 10:39:35
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page8.vue
-->
<template>
  <div class="fx101-page8 pt80">
    <ctitle titleb="IP55级防水防尘"></ctitle>
    <div class="img-div">
      <img
        class="data-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/11.png"
        alt="上太科技"
        @load="imgLoad"
      />
      <div class="i-desc lt-d">
        <span class="line-span"></span>
        <p class="line-label">太阳能板</p>
      </div>
      <div class="i-desc r-desc">
        <span class="line-span"></span>
        <p class="line-label">主体ABS</p>
        <p class="line-label c-g">
          <!-- <span>自主研发配方</span> -->
          <span>高强度抗UV老化</span>
        </p>
      </div>
      <div class="i-desc lb-d">
        <span class="line-span"></span>
        <p class="line-label">不锈钢</p>
      </div>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    imgLoad() {
      let div = document.querySelector(".fx101-page8");
      let img = div.querySelector(".data-img");
      let lp = div.querySelector(".lt-d");
      let lb = div.querySelector(".lb-d");
      let rp = div.querySelector(".r-desc");
      rp.style.left = `calc(50% + ${img.width / 2}px)`;
      lp.style.right = `calc(50% + ${img.width / 3}px - 5px)`;
      lb.style.right = `calc(50% - ${img.width / 14.5}px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fx101-page8 {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .img-div {
    height: 80%;
    margin-top: 50px;
    position: relative;
    img {
      height: 100%;
    }
  }
}

.i-desc {
  position: absolute;
  top: 34%;
  overflow: hidden;
  &.r-desc {
    text-align: right;
  }
  &.lb-d {
    top: 45%;
    .line-span {
      left: unset;
      right: 0;
    }
    .line-label {
      padding-left: 0;
      font-weight: bold;
      padding-right: 200px;
    }
  }
  &.lt-d {
    top: 7%;
    .line-span {
      left: unset;
      right: 0;
    }
    .line-label {
      padding-left: 0;
      font-weight: bold;
      padding-right: 100px;
    }
  }
  .line-span {
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 200px;
    height: 1px;
    background-color: rgba($color: #fff, $alpha: 0.5);
  }
  p {
    margin: 0;
    white-space: nowrap;
    color: $color-active;
    padding-left: 100px;
    position: relative;
    height: 0;
    // height: 16px;
    margin-top: 10px;
    overflow: hidden;
    font-size: 22px;
    font-weight: bold;
    &.c-g {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      opacity: 0.6;
    }
  }
}
@media screen and (max-height: 800px) {
  .fx101-page8 {
    .img-div {
      height: 68%;
    }
  }
}
@media screen and (min-height: 800px) {
  .fx101-page8 {
    .img-div {
      height: 78%;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .fx101-page8 {
    .img-div {
      height: 78%;
    }
    .i-desc {
      position: absolute;
      top: 34%;
      overflow: hidden;
      width: 1.82rem;
      &.r-desc {
        text-align: right;
      }
      &.lb-d {
        top: 45%;
        .line-span {
          left: unset;
          right: 0;
        }
        .line-label {
          padding-left: 0;
          font-weight: bold;
          padding-right: 0;
        }
      }
      &.lt-d {
        top: 7%;
        .line-span {
          left: unset;
          right: 0;
        }
        .line-label {
          padding-left: 0;
          font-weight: bold;
          padding-right: 0;
        }
      }
      .line-span {
        display: block;
        position: absolute;
        top: 0px;
        left: 0;
        width: 1.82rem;
        height: 1px;
        background-color: rgba($color: #fff, $alpha: 0.5);
      }
      p {
        margin: 0;
        white-space: unset;
        color: $color-active;
        padding-left: 0;
        position: relative;
        // height: 0.29rem;
        height: auto;
        margin-top: 10px;
        overflow: hidden;
        font-size: 0.2rem;
        font-weight: bold;
        &.c-g {
          font-size: 0.16rem;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.6;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
</style>
