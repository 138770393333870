<!--
 * @Author: your name
 * @Date: 2021-12-03 16:45:29
 * @LastEditTime: 2021-12-23 10:20:40
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page55.vue
-->
<template>
  <div class="fx101-page55 pt80">
    <div class="text-div">
      <ctitle titleb="超强风机功率，" titles="刷新吸虫阈值"></ctitle>
    </div>
    <div class="img-div">
      <img
        class="d-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/9.png"
        alt="上太科技"
        @load="imgLoad"
      />
      <img
        class="data-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/9-1.gif"
        alt="上太科技"
      />
      <div class="i-desc">
        <span class="line-span"></span>
        <p class="line-label">超高性能风机</p>
      </div>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1.vue";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    imgLoad() {
      let div = document.querySelector(".fx101-page55");
      let img = div.querySelector(".data-img");
      let p = div.querySelector(".i-desc");
      p.style.left = `calc(50% + ${img.width / 4}px + 10px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
.fx101-page55 {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-div {
  height: 78%;
  margin-top: 50px;
  position: relative;
  img {
    height: 100%;
    object-fit: contain;
  }
  .data-img {
    position: absolute;
    height: unset;
    width: 52%;
    top: 42%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.i-desc {
  position: absolute;
  top: 50%;
  overflow: hidden;
  .line-span {
    display: block;
    position: absolute;
    top: 0px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: rgba($color: #fff, $alpha: 0.5);
  }
  p {
    margin: 0;
    white-space: nowrap;
    color: $color-active;
    padding-left: 100px;
    position: relative;
    height: 0;
    // height: 16px;
    font-weight: bold;
    font-size: 22px;
    margin-top: 10px;
  }
}
@media screen and (max-height: 800px) {
  .fx101-page5 {
    .img-div {
      height: 68%;
    }
  }
}
@media screen and (min-height: 800px) {
  .fx101-page5 {
    .img-div {
      height: 78%;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .fx101-page55 {
    height: unset;
    .img-div {
      width: 40%;
      .d-img {
        width: 100%;
      }

      .data-img {
        top: 41.05%;
      }
    }

    .i-desc {
      .line-span {
        width: 100px;
      }
      p {
        height: 16px;
        font-size: 0.22rem;
        padding-left: 0;
        text-align: right;
      }
    }
  }
}
</style>
