<!--
 * @Author: your name
 * @Date: 2021-12-03 08:42:59
 * @LastEditTime: 2021-12-23 10:26:03
 * @LastEditors: Please set LastEditors
 * @Description:
 * @FilePath: \new-website-1123\src\views\special\fx101\page6.vue
-->
<template>
  <div class="fx101-page6 pt80">
    <div class="img-div">
      <img
        class="d-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/10.png"
        alt="上太科技"
      />
      <img
        class="gif-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/10-1.gif"
        alt=""
      />
    </div>
    <div class="text-div">
      <ctitle titleb="智能感应·自动倒虫"></ctitle>
      <p class="desc-p">高精度智能传感器，实时探知虫仓情况，云脑全时段控制</p>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1.vue";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.fx101-page6 {
  height: 100vh;
  position: relative;
}
.img-div {
  position: absolute;
  top: 0;
  left: calc(50% + 100px);
  height: 80%;
  .d-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .gif-img {
    position: absolute;
    top: 36.7%;
    left: 50%;
    transform: translateX(-50.5%);
    width: 59.1%;
  }
}
.text-div {
  padding-left: 11vw;
}
.desc-p {
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba($color: #fff, $alpha: 0.6);
  line-height: 40px;
  margin-top: 50px;
}
@media screen and (max-width: $mobile-width) {
  .fx101-page6 {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img-div {
      position: relative;
      width: 50%;
      height: auto;
      left: 0;
    }
  }

  .text-div {
    padding-left: 12px;
    padding-right: 12px;
    text-align: center;
    margin-top: 20px;
    .desc-p {
      margin-top: 16px;
      font-size: 0.27rem;
      line-height: 0.44rem;
    }
  }
}
</style>
