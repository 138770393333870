<!--
 * @Author: your name
 * @Date: 2021-12-03 08:48:05
 * @LastEditTime: 2021-12-23 10:32:21
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\fx101\page7.vue
-->
<template>
  <div
    class="fx101-page7"
    @mouseup.left="isWidth = false"
    @mousemove="mousemove"
  >
    <!-- 晚上 -->
    <div class="n-div c-div">
      <div class="img-div">
        <img
          class="data-img data-1"
          src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/img-ws.png"
          alt="上太科技"
        />
      </div>
      <div class="text-div">
        <span class="l-span">多维度光照感知</span>
        <span class="l2-span">智能精准灯光控制</span>
        <span class="l3-span">光控·雨控·时控更精准</span>
      </div>
    </div>
    <!-- 白天 -->
    <div class="d-div c-div">
      <img
        class="data-img data-2"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/img-bt.png"
        alt="上太科技"
        @load="imgLoad"
      />
    </div>
    <div class="btns-div">
      <div class="btn-div l-btn shou" @click="nightClick">
        <img
          src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/n.png"
          alt="上太科技"
        />
      </div>
      <div class="btn-div r-btn shou" @click="dayClick">
        <img
          src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/d.png"
          alt="上太科技"
        />
      </div>
    </div>
    <div
      class="change-width shou"
      @mousedown.left="mousedown"
      @dblclick="backInti"
    >
      <!-- <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/nt.png" alt="上太科技" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      isWidth: false,
      oX: 0,
    };
  },
  created() {},
  methods: {
    backInti() {
      let lef = document.querySelector(".fx101-page7 .n-div");
      let img = document.querySelector(".fx101-page7 .change-width");
      lef.style.width = "50%";
      img.style.left = "calc(50% - 40px)";
    },
    /**
     * @description: 晚上点击
     */
    nightClick() {
      let lef = document.querySelector(".fx101-page7 .n-div");
      let img = document.querySelector(".fx101-page7 .change-width");
      if (lef.style.width.indexOf(100) == 0) {
        lef.style.width = "0";
        img.style.left = "-40px";
      } else {
        lef.style.width = "100%";
        img.style.left = "calc(100% - 40px)";
      }
    },
    /**
     * @description: 白天点击
     */
    dayClick() {
      let lef = document.querySelector(".fx101-page7 .n-div");
      let img = document.querySelector(".fx101-page7 .change-width");
      if (lef.style.width.indexOf(0) == 0) {
        lef.style.width = "100%";
        img.style.left = "calc(100% - 40px)";
      } else {
        lef.style.width = "0";
        img.style.left = "-40px";
      }
    },
    imgLoad() {
      let img1 = document.querySelector(".fx101-page7 .data-1");
      let img2 = document.querySelector(".fx101-page7 .data-2");
      img1.style.width = img2.width + "px";
    },
    mousedown(e) {
      this.oX = e.offsetX;
      this.isWidth = true;
    },
    mousemove(e) {
      if (!this.isWidth) return;
      let ww = window.innerWidth;
      let lef = document.querySelector(".fx101-page7 .n-div");
      let img = document.querySelector(".fx101-page7 .change-width");
      let rate = ((e.pageX - this.oX + 8.5) / ww) * 100 + "%";
      img.style.left = rate;

      lef.style.width = `calc(${rate} + 40px)`;
    },
  },
};
</script>

<style lang='scss' scoped>
.fx101-page7 {
  position: relative;
  height: 100vh;
  user-select: none;
  overflow: hidden;
  .c-div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    .data-img {
      position: absolute;
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
    &.n-div {
      width: 50%;
      z-index: 2;
      overflow: hidden;
      .img-div {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .text-div {
        position: absolute;
        z-index: 3;
        color: #fff;
        display: flex;
        flex-direction: column;
        top: 300px;
        left: 11vw;
        span {
          white-space: nowrap;
        }
        .l-span {
          font-size: 60px;
          font-weight: bold;
          color: #ffffff;
          line-height: 80px;
        }
        .l2-span {
          font-size: 60px;
          font-weight: 300;
          color: #ffffff;
          line-height: 80px;
        }
        .l3-span {
          font-size: 24px;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          opacity: 0.6;
        }
      }
    }
    &.d-div {
      width: 100%;
      z-index: 1;
    }
  }
  @keyframes btnA {
    from,
    to {
      transform: scale(1);
    }
    50% {
      transform: scale(1.07);
    }
  }
  .btn-div {
    position: absolute;
    z-index: 2;
    top: 110px;
    animation: btnA 2s infinite linear;
    &.l-btn {
      left: 12vw;
    }
    &.r-btn {
      right: 12vw;
    }
  }
  .change-width {
    position: absolute;
    left: calc(50% - 40px);
    bottom: 10vh;
    z-index: 3;
    width: 80px;
    height: 80px;
    animation: btnA 2s infinite linear;
    background-image: url("https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/fx101/nt.png");
    background-size: 100% 100%;
  }
}

@media screen and (max-width: $mobile-width) {
  .fx101-page7 {
    .c-div {
      &.n-div {
        .text-div {
          display: none;
        }
      }
    }
    .btn-div {
      img {
        width: 1rem;
        height: 1rem;
      }
      &.l-btn {
        left: 12px;
      }
      &.r-btn {
        right: 12px;
      }
    }
    .change-width {
      display: none;
    }
  }
}
</style>
